import React from 'react'
import Navbar from '../Components/Navbar'
import ItService from '../Components/Home1/ItService'
// import EssentialSolution from '../Components/Home1/EssentialSolution'

const Home1 = () => {
  return (
    <div className='container-fluid'>
        <Navbar/>
        <ItService/>

       
    </div>
  )
}

export default Home1